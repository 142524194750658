<template>
    <main>
        <header>
            <h1 class="display-container__title">The Acid Beast</h1>
        </header>
        <div class="settings-panel">
            <button class="settings-panel-close">X</button>
            <div class="settings-option-container">
                <button type="checkbox" checked="true" name="visualizer" id="enable-visualizer"
                    :class="enableVisualizer ? 'active' : ''">
                    Enable visualizer
                </button>
                <button type="checkbox" name="visualizer-mic" :class="enableVisualizerControls ? 'active' : ''"
                    id="enable-visualizer-controls">
                    Show visualizer controls
                </button>
                <button type="checkbox" name="visualizer-controls" :class="enableVisualizerMic ? 'active' : ''"
                    id="enable-visualizer-mic">
                    Use microphone for visualizer
                </button>
            </div>
        </div>
        <button class="settings-button">Settings</button>
        <div class="display-container">
            <div class="display-container__visual-container">
                <img v-if="!isMobileDevice" alt="logo" class="display-container__logo" width="400" height="473"
                    src="./assets/images/black_melt.svg" />
                <Visualizer v-if="enableVisualizer" :showcontrols="enableVisualizerControls" :audiosrc="audiosrc"
                    :playviz="mediastatus" />
            </div>
        </div>
        <section class="media-lists">
            <ul v-if="computedVideo.length > 0 && computedAudio.length > 0" class="media-lists__selector">
                <li @click="switchSelector('audio')" :class="selector['audio'] || 'disabled'">
                    <h3>Audio</h3>
                </li>
                <span v-if="computedVideo.length > 0" class="separator">/</span>
                <li v-if="computedVideo.length > 0" @click="switchSelector('video')"
                    :class="selector['video'] || 'disabled'">
                    <h3>Videos</h3>
                </li>
            </ul>
            <VideoPlayer v-if="computedVideo.length > 0" v-show="selector['video'] === true" :media="media" title="">
            </VideoPlayer>
            <AudioPlayer v-if="computedAudio.length > 0" v-show="selector['audio'] === true" :media="computedAudio"
                title="" @statusupdate="mediaPlaying" @currentaudiosrc="currentSrc"></AudioPlayer>
        </section>
    </main>
</template>

<script>
import AudioPlayer from "./components/AudioPlayer.vue";
import VideoPlayer from "./components/VideoPlayer.vue";
import Visualizer from "./components/Visualizer.vue";
import { fadeIn, fadeOut, waitForLoad } from "./js/animations";

import "./scss/generic.scss";

export default {
    name: "App",
    meta: [{ description: "Default Title" }],
    components: { VideoPlayer, AudioPlayer, Visualizer },
    data: function () {
        return {
            media: [],
            logo: "",
            selector: { audio: true, video: false },
            audiosrc: HTMLAudioElement,
            mediastatus: false,
            hideLogo: false,
            isMobileDevice: false,
            enableVisualizer: false,
            enableVisualizerMic: false,
            enableVisualizerControls: false,
        };
    },
    beforeMount: function () {
        this.isMobileDevice = this.checkDevice() === "mobile";
        this.enableVisualizer = false;
        window.addEventListener("resize", () => {
            this.isMobileDevice = this.checkDevice() === "mobile";
            this.enableVisualizer = false;
        });
    },
    mounted: function () {
        this.logo = document.querySelector(".display-container__logo");
        document
            .querySelector(".settings-button")
            .addEventListener("click", () => {
                document
                    .querySelector(".settings-panel")
                    .classList.toggle("active");
            });
        document
            .querySelector(".settings-panel-close")
            .addEventListener("click", () => {
                document
                    .querySelector(".settings-panel")
                    .classList.toggle("active");
            });
        document
            .querySelector(".settings-option-container #enable-visualizer")
            .addEventListener("click", () => {
                this.enableVisualizer = !this.enableVisualizer;
            });
        document
            .querySelector(
                ".settings-option-container #enable-visualizer-controls"
            )
            .addEventListener("click", () => {
                this.enableVisualizerControls = !this.enableVisualizerControls;
            });
        document
            .querySelector(".settings-option-container #enable-visualizer-mic")
            .addEventListener("click", () => {
                this.enableVisualizerMic = !this.enableVisualizerMic;
            });
        this.$nextTick(async function () {
            if (!this.isMobileDevice) {
                await waitForLoad(this.logo.getAttribute("src"));
                fadeIn(this.logo);
            }
        });
        this.getMedia();
    },
    methods: {
        getMedia() {
            fetch(
                "https://theacidbeastcms.towdie.com/wp-json/wp/v2/media?per_page=60"
            )
                .then((response) => response.json())
                .then((data) => {
                    this.media = data;
                });
        },
        switchSelector(mediaType) {
            const keys = Object.keys(this.selector);
            keys.forEach((key) => {
                this.selector[key] = false;
            });
            this.selector[mediaType] = true;
        },
        mediaPlaying(data) {
            this.mediastatus = data;
        },
        currentSrc(data) {
            this.audiosrc = data;
        },
        checkDevice() {
            let device;
            if (window.innerWidth >= 1024) {
                device = "desktop";
            } else if (window.innerWidth >= 768) {
                device = "portrait";
            } else {
                device = "mobile";
            }
            return device;
        },
    },
    watch: {
        mediastatus() {
            if (!this.isMobileDevice && this.enableVisualizer) {
                this.mediastatus ? fadeOut(this.logo) : fadeIn(this.logo);
            }
        },
    },
    computed: {
        computedVideo() {
            const videos = this.media.filter((file) => {
                return (
                    file.mime_type.includes("video") && file.categories[0] === 3
                );
            });
            return videos;
        },
        computedAudio() {
            const audioFiles = this.media.filter((file) => {
                return (
                    file.mime_type.includes("audio") && file.categories[0] === 2
                );
            });
            return audioFiles;
        },
        showLogo() {
            return this.mediastatus;
        },
    },
};
</script>

<style lang="scss">
@use "scss/breakpoints";
@use "scss/core-mixins";
@use "scss/variables";
@use "scss/buttons";

:root {
    --controls-margin-top: 0px;
}

main {
    max-width: 100%;
    margin: auto;
    display: grid;
    width: 100%;
    height: 100svh;
    display: grid;
    grid-template: "header" min-content "main" / 1fr;

    @include core-mixins.device(breakpoints.$tabLandscape) {
        padding-bottom: unset;
        height: 100%;
    }
}

header {
    padding: 20px;
    width: auto;
    display: flex;
    justify-content: center;
    font-size: 30px;
    grid-area: header;
}

.media-lists {
    grid-area: main;
    z-index: 10;
}

.settings-button {
    grid-area: main;
    color: white;
    margin-inline: auto;
    margin-top: auto;
    bottom: 10px;
    left: 50%;
    translate: -50% 0;
    position: fixed;
    height: min-content;
    z-index: 20;
}

.settings-panel {
    position: fixed;
    grid-area: 1 / 1 / -1 / -1;
    display: grid;
    grid-template: "main" / 1fr;
    width: 100%;
    height: 100%;
    background: rgb(50, 172, 92);
    background: radial-gradient(circle,
            rgba(50, 172, 92, 1) 0%,
            rgba(0, 212, 255, 1) 100%);
    z-index: 20;
    pointer-events: none;
    scale: 0;
    border-radius: 50%;
    transition: border-radius 0.3s, scale 0.3s;

    &.active {
        opacity: 1;
        pointer-events: all;
        scale: 1;
        border-radius: 0%;
    }
}

.settings-panel-close {
    position: relative;
    margin-inline: auto;
    margin-top: auto;
    bottom: 10px;
    grid-area: main;
    height: min-content;
}

.settings-option-container {
    grid-area: main;
    place-self: center;
    display: grid;
    place-items: center;
    gap: 20px;
}

#enable-visualizer,
#enable-visualizer-controls,
#enable-visualizer-mic {
    &.active {
        @include buttons.buttonActiveState();
    }
}

.display-container {
    padding-top: 20px;
    position: fixed;
    inset: 0;
    place-items: center;
    display: grid;
    grid-template: "title" min-content "visuals" / 1fr;

    &__visual-container {
        display: grid;
        grid-template: "main" / 1fr;
        grid-area: visuals;
        width: 100%;
        height: 90%;
    }

    &__logo {
        opacity: 0;
        width: 50%;
        grid-area: main;
        margin-inline: auto;
        display: flex;
        transition: opacity 0.3s;
        opacity: 1;

        &.hide {
            opacity: 0;
        }
    }

    .visualizer {
        grid-area: main;
        z-index: 10;

        &__container {
            display: grid;
            grid-template: "main" / 1fr;
        }
    }
}
</style>
