<template>
    <figure>
        <video class="video" controls playsinline width="320" height="240">
            <source :src="source" type="video/mp4" />
        </video>
        <figcaption>{{ title }}</figcaption>
    </figure>
</template>

<script>
export default {
    name: "Video-component",
    props: {
        source: String,
        title: String,
    },
};
</script>

<style lang="scss" scoped>
figure {
    padding-bottom: 10px;
    box-shadow: var(--red-shadow);
}
</style>